import React, { useEffect, useReducer, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import "swiper/swiper-bundle.css";
import { Separator } from "../Separator";
import arrow from "../../assets/icons/arrow.webp";
import { useInterval } from "react-use";
import { gsap } from "gsap";
//import "swiper/css/effect-cards";
import styles from "./hero.module.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export function Hero() {
  const { t } = useTranslation();
  const main = useRef();

  const [activeCard, setActiveCard] = useState(4);
  const leftButton = classNames(styles.navButton, styles.prevButton, {
    [styles.disabled]: activeCard === 1,
  });
  const rightButton = classNames(styles.navButton, styles.nextButton, {
    [styles.disabled]: activeCard === 4,
  });
  const leftArrow = classNames(styles.arrow, styles.leftArrow);
  const rightArrow = classNames(styles.arrow, styles.rightArrow);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const slide1Style = classNames(styles.swiperCard, styles.card1);
  const slide2Style = classNames(styles.swiperCard, styles.card2);
  const slide3Style = classNames(styles.swiperCard, styles.card3);
  const slide4Style = classNames(styles.swiperCard, styles.card4);

  const [direction, setDirection] = useState("left");
  const [delay, setDelay] = useState(3000);
  const [isRunnig, toggleIsRunning] = useState(true);

  const [slide1ContStyle, setSlide1ContStyle] = useState(
    classNames(styles.swiperSlide, "card1")
  );

  const [slide2ContStyle, setSlide2ContStyle] = useState(
    classNames(styles.swiperSlide, "card2")
  );
  const [slide3ContStyle, setSlide3ContStyle] = useState(
    classNames(styles.swiperSlide, "card3")
  );
  const [slide4ContStyle, setSlide4ContStyle] = useState(
    classNames(styles.swiperSlide, "card4")
  );

  const Slide1 = () => {
    return (
      <div className={slide1Style}>
        <div className={styles.textContainer}>
          <p className={styles.header}>{t("hero.card4.head")}</p>
          {/*<br />
          <p className={styles.subHeader}>{t("hero.card4.sub")}</p>*/}
          <p className={styles.upperDiv}></p>
          <p className={styles.description}>{t("hero.card4.content")}</p>

          <p className={styles.lowerDiv}></p>
        </div>
      </div>
    );
  };

  const Slide2 = () => {
    return (
      <div className={slide2Style}>
        <div className={styles.textContainer}>
          <p className={styles.header}>{t("hero.card3.head")}</p>
          {/*<br />
          <p className={styles.subHeader}>{t("hero.card3.sub")}</p>*/}
          <p className={styles.upperDiv}></p>
          <p className={styles.description}>{t("hero.card3.content")}</p>
          <p className={styles.lowerDiv}></p>
        </div>
      </div>
    );
  };

  const Slide3 = () => {
    return (
      <div className={slide3Style}>
        <div className={styles.textContainer}>
          <p className={styles.header}>{t("hero.card2.head")}</p>
          {/*<br />
          <p className={styles.subHeader}>{t("hero.card2.sub")}</p>*/}
          <p className={styles.upperDiv}></p>
          <p className={styles.description}>{t("hero.card2.content")}</p>
          <p className={styles.lowerDiv}></p>
        </div>
      </div>
    );
  };

  const Slide4 = () => {
    return (
      <div className={slide4Style}>
        <div className={styles.textContainer}>
          <p className={styles.header}>{t("hero.card1.head")}</p>
          {/*<br />
          <p className={styles.subHeader}>{t("hero.card1.sub")}</p>*/}
          <p className={styles.upperDiv}></p>
          <p className={styles.description}>{t("hero.card1.content")}</p>
          <p className={styles.lowerDiv}></p>
        </div>
      </div>
    );
  };

  const activeCard2 = () => {
    gsap
      .timeline()
      .to(".card1", { x: -100 }, 0)
      .to(".card2", { x: -50 }, 0)
      .to(".card3", { x: 50 }, 0)
      .to(".card4", { x: 100 }, 0)
      .to(".card1", { zIndex: -1 }, "<")
      .to(".card2", { zIndex: 1 }, 0)
      .to(".card3", { zIndex: 0 }, 0)
      .to(".card4", { zIndex: -1 }, 0)

      .to([".card3", ".card2", ".card1", ".card4"], { x: 0 }, ">");
  };

  const activeCard1 = () => {
    gsap
      .timeline()
      .to(".card1", { x: -100 }, 0)
      .to(".card2", { x: -50 }, 0)
      .to(".card3", { x: 50 }, 0)
      .to(".card4", { x: 100 }, 0)
      .to(".card1", { zIndex: 2 }, "<")
      .to(".card2", { zIndex: 1 }, 0)
      .to(".card3", { zIndex: 0 }, 0)
      .to(".card4", { zIndex: -1 }, 0)

      .to([".card3", ".card2", ".card1", ".card4"], { x: 0 }, ">");
  };

  const activeCard3 = () => {
    gsap
      .timeline()
      .to(".card1", { x: -100 }, 0)
      .to(".card2", { x: -50 }, 0)
      .to(".card3", { x: 50 }, 0)
      .to(".card4", { x: 100 }, 0)
      .to(".card1", { zIndex: -1 }, "<")
      .to(".card2", { zIndex: 0 }, 0)
      .to(".card3", { zIndex: 1 }, 0)
      .to(".card4", { zIndex: 0 }, 0)

      .to([".card3", ".card2", ".card1", ".card4"], { x: 0 }, ">");
  };

  const activeCard4 = () => {
    gsap
      .timeline()
      .to(".card1", { x: -100 }, 0)
      .to(".card2", { x: -50 }, 0)
      .to(".card3", { x: 50 }, 0)
      .to(".card4", { x: 100 }, 0)
      .to(".card1", { zIndex: -1 }, "<")
      .to(".card2", { zIndex: 0 }, 0)
      .to(".card3", { zIndex: 1 }, 0)
      .to(".card4", { zIndex: 2 }, 0)

      .to([".card3", ".card2", ".card1", ".card4"], { x: 0 }, ">");
  };

  const handleRightClick = () => {
    if (activeCard === 4) {
    } else if (activeCard < 4) {
      if (activeCard === 1) {
        activeCard2();
      }
      if (activeCard === 2) {
        activeCard3();
      }
      if (activeCard === 3) {
        activeCard4();
      }
      setActiveCard((prevState) => prevState + 1);
    }
  };

  const handleLeftClick = () => {
    if (activeCard === 1) {
    } else if (activeCard > 0) {
      if (activeCard === 4) {
        activeCard3();
      }
      if (activeCard === 3) {
        activeCard2();
      }
      if (activeCard === 2) {
        activeCard1();
      }

      setActiveCard((prevState) => prevState - 1);
    }
  };

  const changeCard = () => {
    if (direction === "left") {
      if (activeCard === 1) {
        setDirection("right");

        handleRightClick();
      } else {
        handleLeftClick();
      }
    }

    if (direction === "right") {
      if (activeCard === 4) {
        setDirection("left");
        handleLeftClick();
      } else {
        handleRightClick();
      }
    }
  };

  useEffect(() => {
    if (activeCard < 1 || activeCard > 4) {
      setActiveCard(4);
    }
  }, [activeCard]);

  useInterval(changeCard, isRunnig ? delay : null);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  return (
    <div className={styles.container}>
      <div className={leftButton} ref={prevRef}>
        <div className={styles.buttonLayer}> </div>
        {/*<div onClick={handleLeftClick} className={leftArrow}></div>*/}
        <div className={leftArrow} onClick={handleLeftClick}>
          <img src={arrow} className={styles.arrowImg} alt={"left arrow"} />
        </div>
      </div>
      <div className={styles.sliderSection}>
        <Swiper
          autoHeight={true}
          className={styles.swiper}
          effect={"cards"}
          grabCursor={true}
          onResize={forceUpdate}
          observer={true}
          observeParents={true}
          modules={[EffectCards]}
          cardsEffect={{
            perSlideRotate: 0,
            perSlideOffset: 8,
            rotate: false,
            slideShadows: false,
          }}
        >
          <SwiperSlide className={styles.swiperSlide}>
            <Slide4 />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <Slide3 />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <Slide2 />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <Slide1 />
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        className={styles.desktopSection}
        onMouseDown={() => toggleIsRunning(false)}
        onMouseUp={() => toggleIsRunning(true)}
        ref={main}
      >
        <div
          className={slide1ContStyle}
          /*onAnimationStart={() => {
            handleStartAnimation(1);
          }}
          onAnimationEnd={() => {
            handleAnimation(1);
          }}*/
        >
          <Slide1 />
        </div>
        <div className={slide2ContStyle}>
          <Slide2 />
        </div>
        <div className={slide3ContStyle}>
          <Slide3 />
        </div>
        <div className={slide4ContStyle}>
          <Slide4 />
        </div>
      </div>
      <div className={rightButton} ref={nextRef}>
        <div className={styles.buttonLayer}> </div>
        <div className={rightArrow} onClick={handleRightClick}>
          <img src={arrow} className={styles.arrowImg} alt={"right arrow"} />
        </div>
      </div>{" "}
      {<Separator />}
      {/*<div style={{ zIndex: 10000 }}>{activeCard}</div>*/}
    </div>
  );
}
