import React, { useEffect, useState } from "react";
import styles from "./App.module.css";
import LanguageSelect from "./utils/language/LanguageSelect";
import { useTranslation } from "react-i18next";
import { MainMenu } from "./components/MainMenu";
import { Hero } from "./components/Hero";
import { Portfolio } from "./components/Portfolio";
import { Philosophy } from "./components/Philosophy";

import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { initGA } from "./utils/ga-utils";
import { Main } from "./components/Main";
import { DsgvoEn } from "./components/DsgvoEn";
import { DsgvoDe } from "./components/DsgvoDe";

function App() {
  const [selected, setSelected] = useState(localStorage.getItem("i18nextLng"));
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };
  useEffect(() => {
    if (selected !== "en") {
      if (!selected.startsWith("de")) {
        setSelected("en");
        //localStorage.setItem("i18nextLng", "en");
      } else {
        //localStorage.setItem("i18nextLng", "de");
        setSelected("de");
      }
    }
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
  const germanDescription =
    "Die ZvW Home  Co. Ltd. ist eine Full-Service Software-Development Firma mit einer Spezialisierung auf Cloud- & SaaS-Loesungen. In diesem Zusammenhang beschaftigt sich die ZvW Home Co. Ltd. mit allen technischen Erfodernissen fuer eine erfolgreiche Implementierung einer massgeschneiderten Gesamtkonzeption. Dies beginnt bereits beim Projektmanagement, geht ueber das Datenbank-Design und endet nicht zuletzt bei der Implementierung von professionellen Backend- sowie Frontend-Loesungen mit einem Fokus auf Usabality & Intuitivitaet in der Nutzung.";
  const englishDescription =
    "ZvW Home Co.Ltd. is a full-service software development company specializing in cloud & SaaS solutions. In this context, ZvW Home Co. Ltd. is dedicated to all the technical requirements for a successful implementation of a tailor-made overall concept. This starts with project management, continues with database design and ends not least with the implementation of professional backend and frontend solutions with a focus on usability and intuitiveness in use.";
  const germanKeywords =
    "Projektmanagement, Datenbanken, Design, Programmierung, Entwicklung, Software-as-a-Service, SaaS, Cloud, Backend Entwicklung, Frontend Entwicklung, APP Entwicklung, iOS, Android.";
  const englishKeywords =
    "Project management, databases, design, programming, development, software-as-a-service, SaaS, cloud, backend development, frontend development, APP development, iOS, Android.";
  const ogDescriptionGer =
    "Die ZvW Home  Co. Ltd. ist eine Full-Service Software-Development Firma mit einer Spezialisierung auf Cloud- & SaaS-Loesungen.";
  const ogDescriptionEng =
    "ZvW Home Co.Ltd. is a full-service software development company specializing in cloud & SaaS solutions.";
  const { t } = useTranslation();

  const handleDeclineCookie = () => {
    Cookies.remove("_ga", { domain: ".zvwhome.com" });
    Cookies.remove("_dat", { domain: ".zvwhome.com" });
    Cookies.remove("_gid", { domain: ".zvwhome.com" });
    Cookies.remove(`_ga_${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG}`, {
      domain: ".zvwhome.com",
    });
  };

  return (
    <>
      <div className={styles.App}>
        <Helmet>
          {selected === "de" && (
            <meta name="keywords" content={germanKeywords} />
          )}
          {selected === "de" && (
            <meta name="description" content={germanDescription} />
          )}
          {selected === "de" && <html lang="de" />}

          {selected === "de" && (
            <meta property="og:description" content={ogDescriptionGer} />
          )}
          {selected === "en" && <html lang="en" />}
          {selected === "en" && (
            <meta name="keywords" content={englishKeywords} />
          )}
          {selected === "en" && (
            <meta name="description" content={englishDescription} />
          )}
          {selected === "en" && (
            <meta property="og:description" content={ogDescriptionEng} />
          )}
        </Helmet>
        {
          <div className={styles.language}>
            <LanguageSelect />
          </div>
        }
        <div className={styles.mainMenu}>
          {<MainMenu selected={selected} />}
        </div>
        <CookieConsent
          buttonText={t("cookie_accept")}
          declineButtonText={t("cookie_reject")}
          enableDeclineButton
          flipButtons
          containerClasses="cookie__container"
          buttonWrapperClasses="cookie__buttons-wrapper"
          buttonClasses="cookie__accept-button"
          declineButtonClasses="cookie__reject-button"
          contentClasses="cookie__content"
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
        >
          {t("cookie")}
        </CookieConsent>
        <Routes>
          <Route index path="/" element={<Main />} />
          <Route index path="/en" element={<Main />} />
          <Route index path="/de" element={<Main />} />
          <Route path="en/privacy" element={<DsgvoEn />} />
          <Route path="de/privacy" element={<DsgvoDe />} />
          <Route index path="*" element={<Main />} />
        </Routes>
        {/*<Hero />*/}

        {/*<div id="portfolio">{<Portfolio />}</div>*/}
        {/*<div id="philosophy">{<Philosophy />}</div>*/}
        {/*<div id="contact">{<Contact />}</div>*/}
      </div>
      <div id="footer">{<Footer selected={selected} />}</div>
    </>
  );
}

export default App;
