import React, { useEffect } from "react";
import styles from "./dsgvoen.module.css";

export const DsgvoEn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Privacy Policy</h3>
      <div>
        <ol>
          <li className={styles.orderedListItem}>
            <h4>Name and address of the company responsible</h4>
            <p>
              The company responsible within the meaning of the General Data
              Protection Regulation and other national data protection laws of
              the member states as well as other data protection regulations is:
            </p>
            <p>Responsible site operator:</p>
            <p className={styles.address}>
              ZvW Home Co. Ltd. <br />
              106/51, Moo 1<br />
              Bophut Koh Samui
              <br />
              Surattani 84320
              <br />
              Thailand
            </p>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Name and address of the data protection officer</h4>
            <p>The data protection officer of the company responsible is:</p>
            <p className={styles.address}>
              ZvW Home Co. Ltd.
              <br />
              106/51, Moo 1<br />
              Bophut Koh Samui
              <br />
              Surattani 84320
              <br />
              Thailand
            </p>
          </li>
          <li className={styles.orderedListItem}>
            <h4>General information on data processing</h4>
            <ol type="a">
              <li>
                <h5>Scope of processing of personal data</h5>
                <p>
                  In principle, we only process the personal data of our users
                  to the extent that this is necessary to provide the
                  functionality of our services and our content and services.
                  The processing of personal data of our users takes place
                  regularly only with the consent of the user. An exception
                  applies in such cases in which it is not possible to obtain
                  prior consent for actual reasons and the processing of the
                  data is permitted by statutory provisions.
                </p>
              </li>
              <li>
                <h5>Legal basis for processing personal data</h5>
                <p>
                  Insofar as we obtain the consent of the data subject for the
                  processing of personal data, Article 6 (1) (a) of the EU
                  General Data Protection Regulation (GDPR) serves as the legal
                  basis.
                </p>
                <p>
                  Article 6 (1) (b) GDPR serves as the legal basis for the
                  processing of personal data required to fulfill a contract to
                  which the data subject is a party. This also applies to
                  processing operations that are necessary to carry out
                  pre-contractual measures.
                </p>
                <p>
                  Insofar as processing of personal data is necessary to fulfill
                  a legal obligation to which our company is subject, Article 6
                  (1) (c) GDPR serves as the legal basis.
                </p>
                <p>
                  In the event that vital interests of the data subject or
                  another natural person require the processing of personal
                  data, Article 6 Paragraph 1 lit. d GDPR serves as the legal
                  basis.
                </p>
                <p>
                  If the processing is necessary to safeguard a legitimate
                  interest of our company or a third party and if the interests,
                  fundamental rights and fundamental freedoms of the person
                  concerned do not outweigh the first interest, Article 6
                  Paragraph 1 Letter f GDPR serves as the legal basis for the
                  processing.
                </p>
              </li>
              <li>
                <h5>Data Erasure and Storage Duration</h5>
                <p>
                  The personal data of the person concerned will be deleted or
                  blocked as soon as the purpose of storage no longer applies.
                  Storage can also take place if this has been provided for by
                  the European or national legislator in EU regulations, laws or
                  other regulations to which the person responsible is subject.
                  The data will also be blocked or deleted if a storage period
                  prescribed by the standards mentioned expires, unless there is
                  a need for further storage of the data for the conclusion or
                  fulfillment of a contract.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Description and scope of data processing</h4>
            <p>
              Each time you access ZvW Home Co. Ltd.'s benefits & services. our
              system automatically collects data and information from the
              computer system of the calling computer.
            </p>
            <p>The following data is collected here:</p>
            <ul>
              <li>
                <span>Information about the browser type and version used</span>
              </li>
              <li>
                <span>The user's operating system</span>
              </li>
              <li>
                <span>The user's internet service provider</span>
              </li>
              <li>
                <span>The IP address of the user</span>
              </li>
              <li>
                <span>The geo-location of the user</span>
              </li>
            </ul>
            <p>
              The log files contain IP addresses or other data that enable
              assignment to a user. This could be the case, for example, if the
              link to the website from which the user arrives at the website or
              the link to the website to which the user switches contains
              personal data.
            </p>
            <p>
              The data is also stored in the log files of our system. This data
              is not stored together with other personal data of the user.
            </p>
            <p>
              The legal basis for the temporary storage of the data and the log
              files is Article 6 Paragraph 1 Letter f GDPR.
            </p>
            <ul>
              <li>
                <span>Date and time of access</span>
              </li>
              <li>
                <span>
                  Websites from which the user's system accesses our website
                </span>
              </li>
              <li>
                <span>
                  Websites accessed by the user's system through our website
                </span>
              </li>
            </ul>
            <p>
              In addition, the user can independently change personal data
              within ZvW Home Co. Ltd. deposit. This takes place partly during
              registration, partly during the use of the services of ZvW Home
              Co. Ltd. The data that can be stored by the user are:
            </p>
            <ul>
              <li>
                <span>Date of birth</span>
              </li>
              <li>
                <span>The gender of the user</span>
              </li>
              <li>
                <span>A personal email address to be verified</span>
              </li>
            </ul>

            <ol type={"a"}>
              <li>
                <h5>Purpose of data processing</h5>
                <p>
                  The temporary storage of the IP address by the system is
                  necessary to enable delivery of the website to the user's
                  computer. For this purpose, the IP address of the user must
                  remain stored for the duration of the session.
                </p>
                <p>
                  Storage in log files takes place to ensure the functionality
                  of the website. In addition, we use the data to optimize the
                  website and to ensure the security of our information
                  technology systems. An evaluation of the data for marketing
                  purposes does not take place in this context.
                </p>
                <p>
                  The personal data stored by the user are used within ZvW Home
                  Co. Ltd. only for the personal presentation of the user and
                  are maintained by ZvW Home Co. Ltd. not passed on to third
                  parties. Furthermore, the user has the option of sharing his
                  private image material with other users in a private mode, so
                  that this image material marked as private is not available to
                  all users of ZvW Home Co. Ltd. is shown.
                </p>
                <p>
                  The same applies to private messages that 2 users exchange
                  with each other.
                </p>
                <p>
                  Our legitimate interest in data processing in accordance with
                  Article 6 (1) (f) GDPR also lies in these purposes.
                </p>
              </li>
              <li>
                <h5>Duration of storage</h5>
                <p>
                  The data will be deleted as soon as they are no longer
                  required to achieve the purpose for which they were collected.
                  In the case of the collection of data for the provision of the
                  website, this is the case when the respective session has
                  ended.
                </p>
                <p>
                  If the data is stored in log files, this is the case after
                  seven days at the latest. Storage beyond this is possible. In
                  this case, the IP addresses of the users are deleted or
                  alienated so that it is no longer possible to assign the
                  calling client.
                </p>
              </li>
              <li>
                <h5>Possibility of objection and elimination</h5>
                <p>
                  The collection of data for the provision of the website and
                  the storage of the data in log files is necessary for the
                  operation of the services of ZvW Home Co. Ltd. absolutely
                  necessary. Consequently, there is no possibility of objection
                  on the part of the user.
                </p>
              </li>
            </ol>
          </li>

          <li className={styles.orderedListItem}>
            <h4>Use of Cookies</h4>
            <ol type={"a"}>
              <li>
                <h5>Description and scope of data processing</h5>
                <p>
                  Our website uses cookies. Cookies are text files that are
                  stored in the internet browser or by the internet browser on
                  the user's computer system. If a user calls up a website, a
                  cookie can be stored on the user's operating system. This
                  cookie contains a characteristic character string that enables
                  the browser to be clearly identified when the website is
                  called up again.
                </p>
                <p>
                  The user data collected in this way is pseudonymized by
                  technical precautions. It is therefore no longer possible to
                  assign the data to the calling user. The data is not stored
                  together with other personal data of the user.
                </p>
                <p>
                  When accessing our website, users are informed by an
                  information banner about the use of cookies for analysis
                  purposes and are referred to this data protection declaration.
                  In this context, there is also a reference to how the storage
                  of cookies can be prevented in the browser settings.
                </p>
              </li>
              <li>
                <h5>Legal basis for data processing</h5>

                <p>
                  The legal basis for the processing of personal data using
                  technically necessary cookies is Article 6 (1) (f) GDPR.
                </p>
                <p>
                  The legal basis for the processing of personal data using
                  cookies for analysis purposes is Article 6(1)(a) GDPR if the
                  user has given their consent.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Newsletter</h4>
            <p>
              The newsletter is sent based on the user's registration within the
              services of ZvW Home Co. Ltd. you have the option of subscribing
              to a free newsletter. When registering for the newsletter, the
              data from the input mask is transmitted to us
            </p>
            <p>
              In addition, the following data is collected during registration:
            </p>
            <ul>
              <li>
                <span>IP address of the calling computer</span>
              </li>
              <li>
                <span>Date and time of registration</span>
              </li>
            </ul>
            <p>
              Your consent will be obtained for the processing of the data as
              part of the registration process and reference will be made to
              this data protection declaration.
            </p>
            <p>
              There is no transfer of data to third parties in connection with
              data processing for sending newsletters. The data will only be
              used to send the newsletter.
            </p>
            <ol type={"a"}>
              <li>
                <h5>Purpose of data processing</h5>
                <p>
                  The collection of the user's e-mail address serves to deliver
                  the newsletter.
                </p>
                <p>
                  The collection of other personal data as part of the
                  registration process serves to prevent misuse of the services
                  or the email address used.
                </p>
              </li>
              <li>
                <h5>Duration of storage</h5>
                <p>
                  The data will be deleted as soon as they are no longer
                  required to achieve the purpose for which they were collected.
                  The e-mail address of the user is therefore stored as long as
                  the subscription to the newsletter is active.
                </p>
              </li>
              <li>
                <h5>Possibility of objection and elimination</h5>
                <p>
                  The subscription to the newsletter can be canceled by the user
                  concerned at any time. For this purpose, there is a
                  corresponding link in every newsletter.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Registration</h4>
            <p>
              With the services of ZvW Home Co. Ltd. we offer users the
              opportunity to register by providing personal data. The data is
              entered into an input mask and transmitted to us and stored. At
              the time of registration, the following data is also stored:
            </p>
            <ul>
              <li>The IP address of the user</li>
              <li>Date and time of registration</li>
            </ul>
            <p>
              As part of the registration process, the user's consent to the
              processing of this data is obtained.
            </p>
            <ol type={"a"}>
              <li>
                <h5>Legal basis for data processing</h5>
                <p>
                  The legal basis for processing the data is Article 6(1)(a)
                  GDPR and Article 6(1)(b) GDPR if the user has given their
                  consent.
                </p>
              </li>
              <li>
                <h5>Purpose of data processing</h5>
                <p>
                  Registration of the user is required for the provision of
                  certain content and services on our website.
                </p>
              </li>
              <li>
                <h5>Duration of storage</h5>
                <p>
                  The data will be deleted as soon as they are no longer
                  required to achieve the purpose for which they were collected.
                  This is the case for the data collected during the
                  registration process when registering for the services of ZvW
                  Home Co. Ltd. is repealed or modified.
                </p>
              </li>
              <li>
                <h5>Possibility of objection and elimination</h5>
                <p>
                  As a user, you have the option to cancel the registration at
                  any time. You can have the data stored about you changed at
                  any time. To do this, simply send an email to:
                </p>
                <p>
                  <a
                    className={styles.link}
                    href="mailto:protection@zvwhome.com"
                    rel="noreferrer"
                  >
                    protection@zvwhome.com
                  </a>
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Contact form and email contact</h4>
            <ol type={"a"}>
              <li>
                <h5>Description and scope of data processing</h5>
                <p>
                  Within the benefits & services of ZvW Home Co. Ltd. There is a
                  contact form which can be used for electronic contact. If a
                  user takes advantage of this option, the data entered in the
                  input mask will be transmitted to us and saved. These dates
                  are:
                </p>
                <ul>
                  <li>
                    <span>Your message and/or request</span>
                  </li>
                  <li>
                    <span>
                      Your user assignment is based on the mandatory prior
                      registration
                    </span>
                  </li>
                </ul>
                <p>
                  Alternatively, you can contact us via the email address
                  provided. In this case, the user's personal data transmitted
                  with the e-mail will be stored.
                </p>
                <p>
                  In this context, the data will not be passed on to third
                  parties. The data will only be used to process the
                  conversation.
                </p>
              </li>
              <li>
                <h5>Legal basis for data processing</h5>
                <p>
                  The legal basis for processing the data is Article 6(1)(a)
                  GDPR if the user has given their consent.
                </p>
                <p>
                  The legal basis for the processing of the data transmitted in
                  the course of sending an e-mail is Article 6 Paragraph 1
                  Letter f GDPR. If the e-mail contact is aimed at concluding a
                  contract, the additional legal basis for processing is Art. 6
                  (1) (b) GDPR.
                </p>
              </li>
              <li>
                <h5>Purpose of data processing</h5>
                <p>
                  The processing of the personal data from the input mask serves
                  us solely to process the contact. If contact is made by
                  e-mail, this is also the necessary legitimate interest in the
                  processing of the data.
                </p>
                <p>
                  The other personal data processed during the sending process
                  serve to prevent misuse of the contact form and to ensure the
                  security of our information technology systems.
                </p>
              </li>
              <li>
                <h5>Duration of storage</h5>
                <p>
                  The data will be deleted as soon as they are no longer
                  required to achieve the purpose for which they were collected.
                  For the personal data from the input mask of the contact form
                  and those sent by e-mail, this is the case when the respective
                  conversation with the user has ended. The conversation is over
                  when it can be inferred from the circumstances that the facts
                  in question have been finally clarified.
                </p>
                <p>
                  The additional personal data collected during the sending
                  process will be deleted after a period of seven days at the
                  latest.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Possibility of objection and elimination</h4>
            <p>
              The user has the option to revoke his consent to the processing of
              personal data at any time. If the user contacts us by email, he
              can object to the storage of his personal data at any time. In
              such a case, the conversation cannot be continued.
            </p>
            <p>
              In this case, please inform us immediately within the existing
              communication line that you object to the storage of your data in
              the course of this communication.
            </p>
            <p>
              All personal data that was saved in the course of making contact
              will be deleted in this case.
            </p>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Rights of data subjects</h4>
            <ol type={"a"}>
              <li>
                <h5>Right of providing information</h5>
                <p>
                  You can request confirmation from the person responsible as to
                  whether personal data relating to you is being processed by
                  us. If such processing is present, you can request information
                  from the person responsible for the following information:
                </p>
                <ul>
                  <li>
                    <span>
                      the purposes for which the personal data are processed;
                    </span>
                  </li>
                  <li>
                    <span>
                      the categories of personal data being processed;
                    </span>
                  </li>

                  <li>
                    <span>
                      the recipients or categories of recipients to whom your
                      personal data has been or will be disclosed;
                    </span>
                  </li>
                  <li>
                    <span>
                      the planned duration of the storage of the personal data
                      concerning you or, if specific information on this is not
                      possible, criteria for determining the storage duration;
                    </span>
                  </li>
                  <li>
                    <span>
                      the existence of a right to rectification or erasure of
                      personal data concerning you, a right to restriction of
                      processing by the person responsible or a right to object
                      to this processing;
                    </span>
                  </li>
                  <li>
                    <span>
                      all available information about the origin of the data, if
                      the personal data are not collected from the data subject;
                    </span>
                  </li>
                  <li>
                    <span>
                      the existence of automated decision-making including
                      profiling in accordance with Art. 22 Para. 1 and 4 GDPR
                      and - at least in these cases - meaningful information
                      about the logic involved as well as the scope and intended
                      effects of such processing for the data subject. You have
                      the right to request information as to whether your
                      personal data is being transmitted to a third country or
                      to an international organization. In this context, you can
                      request to be informed of the appropriate guarantees
                      pursuant to Art. 46 GDPR in connection with the
                      transmission.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Right to Rectification</h5>
                <p>
                  You have a right to correction and/or completion to the person
                  responsible if the processed personal data concerning you is
                  incorrect or incomplete. The person responsible must make the
                  correction immediately.
                </p>
              </li>
              <li>
                <h5>Right to restriction of processing</h5>
                <p>
                  Under the following conditions, you can request the
                  restriction of the processing of your personal data:
                </p>
                <ul>
                  <li>
                    <span>
                      If you contest the accuracy of the personal data
                      concerning you for a period of time that enables the
                      controller to verify the accuracy of the personal data;
                    </span>
                  </li>
                  <li>
                    <span>
                      If the processing of the personal data concerning you has
                      been restricted, this data – apart from its storage – may
                      only be used with your consent or to assert, exercise or
                      defend legal claims or to protect the rights of another
                      natural or legal person or for important public reasons
                      interest of the Union or a Member State are processed;
                    </span>
                  </li>
                  <li>
                    <span>
                      If the restriction of processing has been restricted
                      according to the above conditions, you will be informed by
                      the person responsible before the restriction is lifted.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Right to Erasure</h5>
                <p>
                  You can request the person responsible to delete the personal
                  data concerning you immediately, and the person responsible is
                  obliged to delete this data immediately if one of the
                  following reasons applies:
                </p>
                <ul>
                  <li>
                    <span>
                      The personal data concerning you are no longer necessary
                      for the purposes for which they were collected or
                      otherwise processed.
                    </span>
                  </li>
                  <li>
                    <span>
                      You revoke your consent on which the processing was based
                      pursuant to Article 6 Paragraph 1 Letter a or Article 9
                      Paragraph 2 Letter a GDPR and there is no other legal
                      basis for the processing.
                    </span>
                  </li>
                  <li>
                    <span>
                      You object to the processing pursuant to Article 21(1)
                      GDPR and there are no overriding legitimate reasons for
                      the processing, or you object to the processing pursuant
                      to Article 21(2) GDPR.
                    </span>
                  </li>
                  <li>
                    <span>
                      Erasure of your personal data is necessary to fulfill a
                      legal obligation under Union or Member State law to which
                      the controller is subject.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Exceptions:</h5>
                <p>
                  The right to erasure does not exist if processing is
                  necessary:
                </p>
                <ul>
                  <li>
                    <span>
                      to exercise the right to freedom of expression and
                      information;
                    </span>
                  </li>
                  <li>
                    <span>
                      to fulfill a legal obligation that requires processing
                      under Union or Member State law to which the controller is
                      subject, or to perform a task carried out in the public
                      interest or in the exercise of official authority vested
                      in the controller;
                    </span>
                  </li>
                  <li>
                    <span>
                      for reasons of public interest in the field of public
                      health in accordance with Article 9 Paragraph 2 Letters h
                      and i and Article 9 Paragraph 3 GDPR;
                    </span>
                  </li>
                  <li>
                    <span>to assert, exercise or defend legal claims.</span>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Right to Object</h4>
            <p>
              You have the right, for reasons arising from your particular
              situation, to object at any time to the processing of your
              personal data, which is based on Article 6 Paragraph 1 lit. e or f
              GDPR; this also applies to profiling based on these provisions.
            </p>
            <p>
              The person responsible will no longer process the personal data
              relating to you unless he can demonstrate compelling legitimate
              grounds for the processing which outweigh your interests, rights
              and freedoms, or the processing serves to assert, exercise or
              defend legal claims.
            </p>
            <p>
              If the personal data concerning you is processed in order to
              operate direct advertising, you have the right to object at any
              time to the processing of your personal data for the purpose of
              such advertising; this also applies to profiling insofar as it is
              associated with such direct advertising.
            </p>
            <p>
              If you object to the processing for direct marketing purposes, the
              personal data relating to you will no longer be processed for
              these purposes.
            </p>
            <p>
              Notwithstanding Directive 2002/58/EC, you have the option of
              exercising your right of objection in connection with the use of
              information society services by means of automated procedures that
              use technical specifications.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};
