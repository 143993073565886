import React, { useEffect } from "react";
import styles from "./dsgvode.module.css";

export const DsgvoDe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Datenschutzbestimmungen</h3>
      <div>
        <ol>
          <li className={styles.orderedListItem}>
            <h4>Name und Anschrift des Verantwortlichen</h4>
            <p>
              Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
              anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
              sonstiger datenschutzrechtlicher Bestimmungen ist die:
            </p>
            <p>Verantwortlicher Seitenbetreiber:</p>
            <p className={styles.address}>
              ZvW Home Co. Ltd. <br />
              106/51, Moo 1<br />
              Bophut Koh Samui
              <br />
              Surattani 84320
              <br />
              Thailand
            </p>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Name und Anschrift des Datenschutzbeauftragten</h4>
            <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
            <p className={styles.address}>
              ZvW Home Co. Ltd.
              <br />
              106/51, Moo 1<br />
              Bophut Koh Samui
              <br />
              Surattani 84320
              <br />
              Thailand
            </p>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Allgemeines zur Datenverarbeitung</h4>
            <ol type="a">
              <li>
                <h5>Umfang der Verarbeitung personenbezogener Daten</h5>
                <p>
                  Wir verarbeiten personenbezogene Daten unserer Nutzer
                  grundsätzlich nur, soweit dies zur Bereitstellung der
                  Funktionsfähigkeit unserer Dienste sowie unserer Inhalte und
                  Leistungen erforderlich ist. Die Verarbeitung
                  personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur
                  nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen
                  Fällen, in denen eine vorherige Einholung einer Einwilligung
                  aus tatsächlichen Gründen nicht möglich ist und die
                  Verarbeitung der Daten durch gesetzliche Vorschriften
                  gestattet ist.
                </p>
              </li>
              <li>
                <h5>
                  Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                </h5>
                <p>
                  Soweit wir für Verarbeitungsvorgänge personenbezogener Daten
                  eine Einwilligung der betroffenen Person einholen, dient Art.
                  6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als
                  Rechtsgrundlage.
                </p>
                <p>
                  Bei der Verarbeitung von personenbezogenen Daten, die zur
                  Erfüllung eines Vertrages, dessen Vertragspartei die
                  betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1
                  lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
                  Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
                  Maßnahmen erforderlich sind.
                </p>
                <p>
                  Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung
                  einer rechtlichen Verpflichtung erforderlich ist, der unser
                  Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
                  Rechtsgrundlage.
                </p>
                <p>
                  Für den Fall, dass lebenswichtige Interessen der betroffenen
                  Person oder einer anderen natürlichen Person eine Verarbeitung
                  personenbezogener Daten erforderlich machen, dient Art. 6 Abs.
                  1 lit. d DSGVO als Rechtsgrundlage.
                </p>
                <p>
                  Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
                  unseres Unternehmens oder eines Dritten erforderlich und
                  überwiegen die Interessen, Grundrechte und Grundfreiheiten des
                  Betroffenen das erstgenannte Interesse nicht, so dient Art. 6
                  Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
                </p>
              </li>
              <li>
                <h5>Datenlöschung und Speicherdauer</h5>
                <p>
                  Die personenbezogenen Daten der betroffenen Person werden
                  gelöscht oder gesperrt, sobald der Zweck der Speicherung
                  entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn
                  dies durch den europäischen oder nationalen Gesetzgeber in
                  unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
                  Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
                  wurde. Eine Sperrung oder Löschung der Daten erfolgt auch
                  dann, wenn eine durch die genannten Normen vorgeschriebene
                  Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit
                  zur weiteren Speicherung der Daten für einen Vertragsabschluss
                  oder eine Vertragserfüllung besteht.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
            <p>
              Bei jedem Aufruf der Leistungen & Dienste von der ZvW Home Co.
              Ltd. erfasst unser System automatisiert Daten und Informationen
              vom Computersystem des aufrufenden Rechners.
            </p>
            <p>Folgende Daten werden hierbei erhoben:</p>
            <ul>
              <li>
                <span>
                  Informationen über den Browsertyp und die verwendete Version
                </span>
              </li>
              <li>
                <span>Das Betriebssystem des Nutzers</span>
              </li>
              <li>
                <span>Den Internet-Service-Provider des Nutzers</span>
              </li>
              <li>
                <span>Die IP-Adresse des Nutzers</span>
              </li>
              <li>
                <span>Die Geo-Location des Nutzers</span>
              </li>
            </ul>
            <p>
              Die Logfiles enthalten IP-Adressen oder sonstige Daten, die eine
              Zuordnung zu einem Nutzer ermöglichen. Dies könnte beispielsweise
              der Fall sein, wenn der Link zur Website, von der der Nutzer auf
              die Internetseite gelangt, oder der Link zur Website, zu der der
              Nutzer wechselt, personenbezogene Daten enthält.
            </p>
            <p>
              Die Daten werden ebenfalls in den Logfiles unseres Systems
              gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
              personenbezogenen Daten des Nutzers findet nicht statt.
            </p>
            <p>
              Rechtsgrundlage für die vorübergehende Speicherung der Daten und
              der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <ul>
              <li>
                <span>Datum und Uhrzeit des Zugriffs</span>
              </li>
              <li>
                <span>
                  Websites, von denen das System des Nutzers auf unsere
                  Internetseite gelangt
                </span>
              </li>
              <li>
                <span>
                  Websites, die vom System des Nutzers über unsere Website
                  aufgerufen werden
                </span>
              </li>
            </ul>
            <p>
              Zusätzlich kann der Nutzer eigenverantwortlich persönliche Daten
              innerhalb der ZvW Home Co. Ltd. hinterlegen. Dies erfolgt
              teilweise im Rahmen der Registrierung, teilweise während der
              Nutzung der Dienste der ZvW Home Co. Ltd.. Die hierbei vom Nutzer
              hinterlegbaren Daten sind:
            </p>
            <ul>
              <li>
                <span>Das Geburtsdatum</span>
              </li>
              <li>
                <span>Das Geschlecht des Nutzers</span>
              </li>
              <li>
                <span>Eine persönliche zu verifizierende E-Mail Adresse</span>
              </li>
            </ul>

            <ol type={"a"}>
              <li>
                <h5>Zweck der Datenverarbeitung</h5>
                <p>
                  Die vorübergehende Speicherung der IP-Adresse durch das System
                  ist notwendig, um eine Auslieferung der Website an den Rechner
                  des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des
                  Nutzers für die Dauer der Sitzung gespeichert bleiben.
                </p>
                <p>
                  Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit
                  der Website sicherzustellen. Zudem dienen uns die Daten zur
                  Optimierung der Website und zur Sicherstellung der Sicherheit
                  unserer informationstechnischen Systeme. Eine Auswertung der
                  Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
                  statt.
                </p>
                <p>
                  Die vom Nutzer hinterlegten personenbezogenen Daten dienen
                  innerhalb der ZvW Home Co. Ltd. nur der persönlichen
                  Präsentation des Nutzers und werden von der ZvW Home Co. Ltd.
                  nicht an Dritte weitergegeben. Des Weiteren hat der Nutzer die
                  Möglichkeit, sein privates Bildmaterial in einem Privatmodus
                  mit anderen Nutzern zu teilen, so dass dieses als privat
                  markierte Bildmaterial nicht allen Nutzern der ZvW Home Co.
                  Ltd. angezeigt wird.
                </p>
                <p>
                  Gleiches gilt für private Nachrichten, die 2 Nutzer
                  untereinander austauschen.
                </p>
                <p>
                  In diesen Zwecken liegt auch unser berechtigtes Interesse an
                  der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                </p>
              </li>
              <li>
                <h5>Dauer der Speicherung</h5>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des
                  Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle
                  der Erfassung der Daten zur Bereitstellung der Website ist
                  dies der Fall, wenn die jeweilige Sitzung beendet ist.
                </p>
                <p>
                  Im Falle der Speicherung der Daten in Logfiles ist dies nach
                  spätestens sieben Tagen der Fall. Eine darüberhinausgehende
                  Speicherung ist möglich. In diesem Fall werden die IP-Adressen
                  der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des
                  aufrufenden Clients nicht mehr möglich ist.
                </p>
              </li>
              <li>
                <h5>Widerspruchs- und Beseitigungsmöglichkeit</h5>
                <p>
                  Die Erfassung der Daten zur Bereitstellung der Website und die
                  Speicherung der Daten in Logfiles ist für den Betrieb der
                  Leistungen & Services der ZvW Home Co. Ltd. zwingend
                  erforderlich. Es besteht folglich seitens des Nutzers keine
                  Widerspruchsmöglichkeit.
                </p>
              </li>
            </ol>
          </li>

          <li className={styles.orderedListItem}>
            <h4>Verwendung von Cookies</h4>
            <ol type={"a"}>
              <li>
                <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                <p>
                  Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich
                  um Textdateien, die im Internetbrowser bzw. vom
                  Internetbrowser auf dem Computersystem des Nutzers gespeichert
                  werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie
                  auf dem Betriebssystem des Nutzers gespeichert werden. Dieser
                  Cookie enthält eine charakteristische Zeichenfolge, die eine
                  eindeutige Identifizierung des Browsers beim erneuten Aufrufen
                  der Website ermöglicht.
                </p>
                <p>
                  Die auf diese Weise erhobenen Daten der Nutzer werden durch
                  technische Vorkehrungen pseudonymisiert. Daher ist eine
                  Zuordnung der Daten zum aufrufenden Nutzer nicht mehr möglich.
                  Die Daten werden nicht gemeinsam mit sonstigen
                  personenbezogenen Daten der Nutzer gespeichert.
                </p>
                <p>
                  Beim Aufruf unserer Website werden die Nutzer durch einen
                  Infobanner über die Verwendung von Cookies zu Analysezwecken
                  informiert und auf diese Datenschutzerklärung verwiesen. Es
                  erfolgt in diesem Zusammenhang auch ein Hinweis darauf, wie
                  die Speicherung von Cookies in den Browsereinstellungen
                  unterbunden werden kann. Weiteres entnehmen Sie bitte unserer
                  Cookie Policy.
                </p>
              </li>
              <li>
                <h5>Rechtsgrundlage für die Datenverarbeitung</h5>

                <p>
                  Die Rechtsgrundlage für die Verarbeitung personenbezogener
                  Daten unter Verwendung technisch notweniger Cookies ist Art. 6
                  Abs. 1 lit. f DSGVO.
                </p>
                <p>
                  Die Rechtsgrundlage für die Verarbeitung personenbezogener
                  Daten unter Verwendung von Cookies zu Analysezwecken ist bei
                  Vorliegen einer diesbezüglichen Einwilligung des Nutzers Art.
                  6 Abs. 1 lit. a DSGVO.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Newsletter</h4>
            <p>
              Der Newsletter-Versand erfolgt aufgrund der Anmeldung des Nutzers
              innerhalb der Dienste von der ZvW Home Co. Ltd.. Bei der ZvW Home
              Co. Ltd. besteht die Möglichkeit einen kostenfreien Newsletter zu
              abonnieren. Dabei werden bei der Anmeldung zum Newsletter die
              Daten aus der Eingabemaske an uns übermittelt
            </p>
            <p>Zudem werden folgende Daten bei der Anmeldung erhoben:</p>
            <ul>
              <li>
                <span>IP-Adresse des aufrufenden Rechners</span>
              </li>
              <li>
                <span>Datum und Uhrzeit der Registrierung</span>
              </li>
            </ul>
            <p>
              Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs
              Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung
              verwiesen.
            </p>
            <p>
              Es erfolgt im Zusammenhang mit der Datenverarbeitung für den
              Versand von Newslettern keine Weitergabe der Daten an Dritte. Die
              Daten werden ausschließlich für den Versand des Newsletters
              verwendet.
            </p>
            <ol type={"a"}>
              <li>
                <h5>Zweck der Datenverarbeitung</h5>
                <p>
                  Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den
                  Newsletter zuzustellen.
                </p>
                <p>
                  Die Erhebung sonstiger personenbezogener Daten im Rahmen des
                  Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder
                  der verwendeten E-Mail-Adresse zu verhindern.
                </p>
              </li>
              <li>
                <h5>Dauer der Speicherung</h5>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des
                  Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die
                  E-Mail-Adresse des Nutzers wird demnach solange gespeichert,
                  wie das Abonnement des Newsletters aktiv is
                </p>
              </li>
              <li>
                <h5>Widerspruchs- und Beseitigungsmöglichkeit</h5>
                <p>
                  Das Abonnement des Newsletters kann durch den betroffenen
                  Nutzer jederzeit gekündigt werden. Zu diesem Zweck findet sich
                  in jedem Newsletter ein entsprechender Link.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Registrierung</h4>
            <p>
              Mit den Leistungen & Dienste der ZvW Home Co. Ltd. bieten wir
              Nutzern die Möglichkeit, sich unter Angabe personenbezogener Daten
              zu registrieren. Die Daten werden dabei in eine Eingabemaske
              eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe
              der Daten an Dritte findet nicht statt. Im Zeitpunkt der
              Registrierung werden zudem folgende Daten gespeichert:
            </p>
            <ul>
              <li>Die IP-Adresse des Nutzers</li>
              <li>Datum und Uhrzeit der Registrierung</li>
            </ul>
            <p>
              Im Rahmen des Registrierungsprozesses wird eine Einwilligung des
              Nutzers zur Verarbeitung dieser Daten eingeholt.
            </p>
            <ol type={"a"}>
              <li>
                <h5>Rechtsgrundlage für die Datenverarbeitung</h5>
                <p>
                  Rechtsgrundlage für die Verarbeitung der Daten ist bei
                  Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a
                  DSGVO sowie Art. 6 Abs. 1 lit. b DSGVO.
                </p>
              </li>
              <li>
                <h5>Zweck der Datenverarbeitung</h5>
                <p>
                  Eine Registrierung des Nutzers ist für das Bereithalten
                  bestimmter Inhalte und Leistungen auf unserer Website
                  erforderlich.
                </p>
              </li>
              <li>
                <h5>Dauer der Speicherung</h5>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des
                  Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist
                  für die während des Registrierungsvorgangs erhobenen Daten der
                  Fall, wenn die Registrierung bei den Leistungen & Diensten von
                  der ZvW Home Co. Ltd. aufgehoben oder abgeändert wird.
                </p>
              </li>
              <li>
                <h5>Widerspruchs- und Beseitigungsmöglichkeit</h5>
                <p>
                  Als Nutzer haben sie jederzeit die Möglichkeit, die
                  Registrierung aufzulösen. Die über Sie gespeicherten Daten
                  können Sie jederzeit abändern lassen. Um dies zu tun,
                  schreiben Sie einfach eine Email an:
                </p>
                <p>
                  <a
                    className={styles.link}
                    href="mailto:protection@zvwhome.com"
                    rel="noreferrer"
                  >
                    protection@zvwhome.com
                  </a>
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Kontaktformular und E-Mail-Kontakt</h4>
            <ol type={"a"}>
              <li>
                <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                <p>
                  Innerhalb der Leistungen & Dienste der ZvW Home Co. Ltd. ist
                  ein Kontaktformular vorhanden, welches für die elektronische
                  Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese
                  Möglichkeit wahr, so werden die in der Eingabemaske eingegeben
                  Daten an uns übermittelt und gespeichert. Diese Daten sind:
                </p>
                <ul>
                  <li>
                    <span>Ihre Nachricht und/oder Anfrage</span>
                  </li>
                  <li>
                    <span>
                      Ihre Nutzer-Zuordnung erfolgt auf Basis der zwingend
                      vorher getätigten Anmeldung
                    </span>
                  </li>
                </ul>
                <p>
                  Alternativ ist eine Kontaktaufnahme über die bereitgestellte
                  E-Mail-Adresse möglich. In diesem Fall werden die mit der
                  E-Mail übermittelten personenbezogenen Daten des Nutzers
                  gespeichert.
                </p>
                <p>
                  Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten
                  an Dritte. Die Daten werden ausschließlich für die
                  Verarbeitung der Konversation verwendet.
                </p>
              </li>
              <li>
                <h5>Rechtsgrundlage für die Datenverarbeitung</h5>
                <p>
                  Rechtsgrundlage für die Verarbeitung der Daten ist bei
                  Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a
                  DSGVO.
                </p>
                <p>
                  Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge
                  einer Übersendung einer E-Mail übermittelt werden, ist Art. 6
                  Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den
                  Abschluss eines Vertrages ab, so ist zusätzliche
                  Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
                  DSGVO.
                </p>
              </li>
              <li>
                <h5>Zweck der Datenverarbeitung</h5>
                <p>
                  Die Verarbeitung der personenbezogenen Daten aus der
                  Eingabemaske dient uns allein zur Bearbeitung der
                  Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail
                  liegt hieran auch das erforderliche berechtigte Interesse an
                  der Verarbeitung der Daten.
                </p>
                <p>
                  Die sonstigen während des Absendevorgangs verarbeiteten
                  personenbezogenen Daten dienen dazu, einen Missbrauch des
                  Kontaktformulars zu verhindern und die Sicherheit unserer
                  informationstechnischen Systeme sicherzustellen.
                </p>
              </li>
              <li>
                <h5>Dauer der Speicherung</h5>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des
                  Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die
                  personenbezogenen Daten aus der Eingabemaske des
                  Kontaktformulars und diejenigen, die per E-Mail übersandt
                  wurden, ist dies dann der Fall, wenn die jeweilige
                  Konversation mit dem Nutzer beendet ist. Beendet ist die
                  Konversation dann, wenn sich aus den Umständen entnehmen
                  lässt, dass der betroffene Sachverhalt abschließend geklärt
                  ist.
                </p>
                <p>
                  Die während des Absendevorgangs zusätzlich erhobenen
                  personenbezogenen Daten werden spätestens nach einer Frist von
                  sieben Tagen gelöscht.
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
            <p>
              Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
              Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
              Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung
              seiner personenbezogenen Daten jederzeit widersprechen. In einem
              solchen Fall kann die Konversation nicht fortgeführt werden.
            </p>
            <p>
              Teilen Sie uns in diesem Falle bitte umgehend innerhalb des
              bestehenden Kommunikation-Stranges mit, dass Sie der Speicherung
              Ihrer Daten im Zuge dieser Kommunikation widersprechen.
            </p>
            <p>
              Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
              gespeichert wurden, werden in diesem Fall gelöscht.
            </p>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Rechte der betroffenen Personen</h4>
            <ol type={"a"}>
              <li>
                <h5>Auskunftsrecht</h5>
                <p>
                  Sie können von dem Verantwortlichen eine Bestätigung darüber
                  verlangen, ob personenbezogene Daten, die Sie betreffen, von
                  uns verarbeitet werden. Liegt eine solche Verarbeitung vor,
                  können Sie von dem Verantwortlichen über folgende
                  Informationen Auskunft verlangen:
                </p>
                <ul>
                  <li>
                    <span>
                      die Zwecke, zu denen die personenbezogenen Daten
                      verarbeitet werden;
                    </span>
                  </li>
                  <li>
                    <span>
                      die Kategorien von personenbezogenen Daten, welche
                      verarbeitet werden;
                    </span>
                  </li>

                  <li>
                    <span>
                      die Empfänger bzw. die Kategorien von Empfängern,
                      gegenüber denen die Sie betreffenden personenbezogenen
                      Daten offengelegt wurden oder noch offengelegt werden;
                    </span>
                  </li>
                  <li>
                    <span>
                      die geplante Dauer der Speicherung der Sie betreffenden
                      personenbezogenen Daten oder, falls konkrete Angaben
                      hierzu nicht möglich sind, Kriterien für die Festlegung
                      der Speicherdauer;
                    </span>
                  </li>
                  <li>
                    <span>
                      das Bestehen eines Rechts auf Berichtigung oder Löschung
                      der Sie betreffenden personenbezogenen Daten, eines Rechts
                      auf Einschränkung der Verarbeitung durch den
                      Verantwortlichen oder eines Widerspruchsrechts gegen diese
                      Verarbeitung;
                    </span>
                  </li>
                  <li>
                    <span>
                      alle verfügbaren Informationen über die Herkunft der
                      Daten, wenn die personenbezogenen Daten nicht bei der
                      betroffenen Person erhoben werden;
                    </span>
                  </li>
                  <li>
                    <span>
                      das Bestehen einer automatisierten Entscheidungsfindung
                      einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO
                      und – zumindest in diesen Fällen – aussagekräftige
                      Informationen über die involvierte Logik sowie die
                      Tragweite und die angestrebten Auswirkungen einer
                      derartigen Verarbeitung für die betroffene Person.
                    </span>
                  </li>
                  <li>
                    <span>
                      Ihnen steht das Recht zu, Auskunft darüber zu verlangen,
                      ob die Sie betreffenden personenbezogenen Daten in ein
                      Drittland oder an eine internationale Organisation
                      übermittelt werden. In diesem Zusammenhang können Sie
                      verlangen, über die geeigneten Garantien gem. Art. 46
                      DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu
                      werden.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Recht auf Berichtigung</h5>
                <p>
                  Sie haben ein Recht auf Berichtigung und/oder
                  Vervollständigung gegenüber dem Verantwortlichen,sofern die
                  verarbeiteten personenbezogenen Daten, die Sie betreffen,
                  unrichtig oder unvollständig sind. Der Verantwortliche hat die
                  Berichtigung unverzüglich vorzunehmen.
                </p>
              </li>
              <li>
                <h5>Recht auf Einschränkung der Verarbeitung</h5>
                <p>
                  Unter den folgenden Voraussetzungen können Sie die
                  Einschränkung der Verarbeitung der Sie betreffenden
                  personenbezogenen Daten verlangen:
                </p>
                <ul>
                  <li>
                    <span>
                      Wenn Sie die Richtigkeit der Sie betreffenden
                      personenbezogenen für eine Dauer bestreiten, die es dem
                      Verantwortlichen ermöglicht, die Richtigkeit der
                      personenbezogenen Daten zu überprüfen;
                    </span>
                  </li>
                  <li>
                    <span>
                      Wurde die Verarbeitung der Sie betreffenden
                      personenbezogenen Daten eingeschränkt, dürfen diese Daten
                      – von ihrer Speicherung abgesehen – nur mit Ihrer
                      Einwilligung oder zur Geltendmachung, Ausübung oder
                      Verteidigung von Rechtsansprüchen oder zum Schutz der
                      Rechte einer anderen natürlichen oder juristischen Person
                      oder aus Gründen eines wichtigen öffentlichen Interesses
                      der Union oder eines Mitgliedstaats verarbeitet werden.
                    </span>
                  </li>
                  <li>
                    <span>
                      Wurde die Einschränkung der Verarbeitung nach den o.g.
                      Voraussetzungen eingeschränkt, werden Sie von dem
                      Verantwortlichen unterrichtet bevor die Einschränkung
                      aufgehoben wird.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Recht auf Löschung</h5>
                <p>
                  Sie können von dem Verantwortlichen verlangen, dass die Sie
                  betreffenden personenbezogenen Daten unverzüglich gelöscht
                  werden, und der Verantwortliche ist verpflichtet, diese Daten
                  unverzüglich zu löschen, sofern einer der folgenden Gründe
                  zutrifft:
                </p>
                <ul>
                  <li>
                    <span>
                      Die Sie betreffenden personenbezogenen Daten sind für die
                      Zwecke, für die sie erhoben oder auf sonstige Weise
                      verarbeitet wurden, nicht mehr notwendig.
                    </span>
                  </li>
                  <li>
                    <span>
                      Sie widerrufen Ihre Einwilligung, auf die sich die
                      Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2
                      lit. a DSGVO stützte, und es fehlt an einer anderweitigen
                      Rechtsgrundlage für die Verarbeitung.
                    </span>
                  </li>
                  <li>
                    <span>
                      Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
                      Verarbeitung ein und es liegen keine vorrangigen
                      berechtigten Gründe für die Verarbeitung vor, oder Sie
                      legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die
                      Verarbeitung ein.
                    </span>
                  </li>
                  <li>
                    <span>
                      Die Löschung der Sie betreffenden personenbezogenen Daten
                      ist zur Erfüllung einer rechtlichen Verpflichtung nach dem
                      Unionsrecht oder dem Recht der Mitgliedstaaten
                      erforderlich, dem der Verantwortliche unterliegt.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Ausnahmen:</h5>
                <p>
                  Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                  erforderlich ist
                </p>
                <ul>
                  <li>
                    <span>
                      zur Ausübung des Rechts auf freie Meinungsäußerung und
                      Information;
                    </span>
                  </li>
                  <li>
                    <span>
                      zur Erfüllung einer rechtlichen Verpflichtung, die die
                      Verarbeitung nach dem Recht der Union oder der
                      Mitgliedstaaten, dem der Verantwortliche unterliegt,
                      erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                      öffentlichen Interesse liegt oder in Ausübung öffentlicher
                      Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                    </span>
                  </li>
                  <li>
                    <span>
                      aus Gründen des öffentlichen Interesses im Bereich der
                      öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i
                      sowie Art. 9 Abs. 3 DSGVO;
                    </span>
                  </li>
                  <li>
                    <span>
                      zur Geltendmachung, Ausübung oder Verteidigung von
                      Rechtsansprüchen.
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li className={styles.orderedListItem}>
            <h4>Widerspruchsrecht</h4>
            <p>
              Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
              Situation ergeben, jederzeit gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
              1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
              auch für ein auf diese Bestimmungen gestütztes Profiling.
            </p>
            <p>
              Der Verantwortliche verarbeitet die Sie betreffenden
              personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
              schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
              Interessen, Rechte und Freiheiten überwiegen, oder die
              Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
              von Rechtsansprüchen.
            </p>
            <p>
              Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
              um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
              Widerspruch gegen die Verarbeitung der Sie betreffenden
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
              dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht.
            </p>
            <p>
              Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
              so werden die Sie betreffenden personenbezogenen Daten nicht mehr
              für diese Zwecke verarbeitet.
            </p>
            <p>
              Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
              Diensten der Informationsgesellschaft–ungeachtet der Richtlinie
              2002/58/EG–Ihr Widerspruchsrecht mittels automatisierter Verfahren
              auszuüben, bei denen technische Spezifikationen verwendet werden.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};
