import React from "react";
import { Hero } from "../Hero";
import { Portfolio } from "../Portfolio";
import { Philosophy } from "../Philosophy";
import { Contact } from "../Contact";

export const Main = () => {
  return (
    <div>
      <Hero />
      <div id="portfolio">{<Portfolio />}</div>
      <div id="philosophy">{<Philosophy />}</div>
      {<div id="contact">{<Contact />}</div>}
    </div>
  );
};
