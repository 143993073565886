import React from "react";
import styles from "./philosophy.module.css";
import { useTranslation } from "react-i18next";
import { Separator } from "../Separator";
import thinking from "../../assets/images/thinking.png";

export function Philosophy() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h2>{t("philosophy.philosophy_header")}</h2>

      <div className={styles.mainContent}>
        <img className={styles.thinkingImg} src={thinking} alt="thinking" />
      </div>
      <div className={styles.mainText}>
        <p>{t("philosophy.content1")}</p>
        <p>{t("philosophy.content1-2")}</p>
        <figure className={styles.cite}>
          <blockquote className={styles.block} cite={"Douglas Engelbart"}>
            <p>{t("philosophy.cite")}</p>
          </blockquote>
          <figcaption className={styles.caption}>
            ——— Douglas Engelbart ———
          </figcaption>
        </figure>
        <p>{t("philosophy.content2")}</p>
        <p>{t("philosophy.content2-2")}</p>
      </div>

      <Separator />
    </div>
  );
}
