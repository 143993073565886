import React, { useEffect, useState } from "react";
import styles from "./mainmenu.module.css";
import { /*Link,*/ animateScroll as scroll, scroller } from "react-scroll";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.webp";
import { Drawer } from "@mui/material";
import classNames from "classnames";
import LanguageSelect from "../../utils/language/LanguageSelect";

export function MainMenu({ selected }) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", () => setOpen(false));
    window.addEventListener("scroll", () => setOpen(false));
  }, []);

  const burgerClass = classNames(styles.burger, {
    [styles[`open`]]: open,
  });
  const burgerClassS = classNames(styles.burgerSpan, {
    [styles[`open`]]: open,
  });

  const { t } = useTranslation();

  const Menu = () => {
    return (
      <div className={styles.navWrap}>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Link
              style={{
                textDecoration: "none",
                color: "currentColor",
              }}
              //to="portfolio"
              to={`/${selected}`}
              /*spy={true}
              smooth={true}
              offset={-100}
              duration={500}*/
              onClick={() => {
                onClose();
                setTimeout(() => {
                  scroller.scrollTo("portfolio", {
                    duration: 500,
                    delay: 0,
                    smooth: true,
                    //offset: -50,
                    offset: -50,
                  });
                }, 500);
              }}
            >
              {t("header.portfolio_header")}
            </Link>{" "}
          </li>
          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            {" "}
            <Link
              style={{
                textDecoration: "none",
                color: "currentColor",
              }}
              //to="philosophy"
              to={`/${selected}`}
              /*spy={true}
              smooth={true}
              offset={-100}
              duration={500}*/
              onClick={() => {
                onClose();
                setTimeout(() => {
                  scroller.scrollTo("philosophy", {
                    duration: 500,
                    delay: 0,
                    smooth: true,
                    //offset: -50,
                    offset: -100,
                  });
                }, 500);
              }}
            >
              {t("header.philosophy_header")}
            </Link>
          </li>
          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            <a
              style={{
                textDecoration: "none",
                color: "currentColor",
              }}
              href={"https://converse-x-chat.com"}
              onClick={onClose}
            >
              {t("header.chat_header")}
            </a>
          </li>
          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            <Link
              style={{
                textDecoration: "none",
                color: "currentColor",
              }}
              //to="contact"
              to={`/${selected}`}
              /*spy={true}
              smooth={true}
              offset={-100}
              duration={500}*/
              onClick={() => {
                onClose();
                setTimeout(() => {
                  scroller.scrollTo("contact", {
                    duration: 500,
                    delay: 0,
                    smooth: true,
                    //offset: -50,
                    offset: -100,
                  });
                }, 500);
              }}
            >
              {t("header.contact_header")}
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <nav className={styles.header}>
        <div className={styles.mobileMenu}>
          <div className={styles.burgerBtn}>
            <button className={burgerClass} onClick={showDrawer}>
              <span className={burgerClassS}></span>
              <span className={styles.burgerSpan}></span>
              <span className={styles.burgerSpan}></span>
            </button>
          </div>
          <Link
            to={`/${selected}`}
            className={styles.mobileLogoWrap}
            onClick={() => {
              onClose();
              scroll.scrollToTop();
            }}
          >
            <div className={styles.mobileLogo}>
              <img
                className={styles.mobileLogoImg}
                src={logo}
                alt={"company logo"}
              />
            </div>
            <p className={styles.mobileLogoText}>ZVW HOME CO. Ltd</p>
          </Link>
          <LanguageSelect />
        </div>
        <div className={styles.desktopMenu}>
          <Link
            to={`/${selected}`}
            className={styles.logoWrap}
            onClick={scroll.scrollToTop}
          >
            <div className={styles.logo}>
              <img className={styles.logoImg} src={logo} alt={"company logo"} />
            </div>
            <p className={styles.logoText}>ZVW HOME CO. Ltd</p>
          </Link>
          <Menu />
        </div>
      </nav>
      <Drawer sx={{ zIndex: 0 }} onClose={onClose} open={open}>
        <Menu />
      </Drawer>
    </>
  );
}
