import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Field, Form, useField } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./contact.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import classNames from "classnames";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.webp";

const TextField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <label className={styles.labelStyle}>{label}</label>
      <textarea
        style={{ height: 120, resize: "none" }}
        {...field}
        {...props}
        maxLength="200"
      />

      {meta.touched && meta.error ? (
        <div className={styles.errorMessageField}>{meta.error}</div>
      ) : null}
    </>
  );
};

const PhoneField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <label className={styles.labelStyle} style={{ marginBottom: 5 }}>
        {label}
      </label>

      <PhoneInput
        {...field}
        {...props}
        //country={"de"}
        value={field.value}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        //onBlur={(e) => handleBlur(e)}
        defaultMask="... ... ..."
        // masks={{ in: ".... ... ..." }}
        // onlyCountries={["in"]}
        inputProps={{
          name: "phone",
          // required: true,
          //autoFocus: true,
        }}
        isValid={(value, country) => {
          if (value.match(/12345/)) {
            return "Invalid value: " + value + ", " + country.name;
          } else return !value.match(/1234/);
        }}
        disableSearchIcon={true}
        //enableAreaCodeStretch
        copyNumbersOnly={false}
        disableDropdown={true}
        inputStyle={{
          background: "transparent",
          border: "1px solid grey",
          //border: "none",
          //height: "3em",
          width: "100%",
          outline: "none",
        }}
        buttonStyle={{
          height: "3em",
          background: "transparent",
          outline: "none",
          border: "none",
          margin: "auto",
        }}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </>
  );
};

export function Contact() {
  const gKey = `${process.env.REACT_APP_CAPTCHA_KEY}`;
  const [disabled, setDisabled] = useState(true);
  const [token, setToken] = useState(null);
  const captchaRef = useRef();
  const { t } = useTranslation();
  const nameField = classNames(styles.inputContainer, styles.nameField);
  const skypeField = classNames(styles.inputContainer, styles.skypeField);
  const phoneField = classNames(styles.inputContainer, styles.phoneField);
  const mailField = classNames(styles.inputContainer, styles.mailField);
  const messageField = classNames(styles.inputContainer, styles.messageField);

  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const center = {
    lat: 9.558056,
    lng: 100.026528,
  };

  function onChange(value) {
    if (value) {
      setDisabled(false);
      setToken(value);
    } else {
      setDisabled(true);
      setToken(null);
    }
  }
  const mailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const ValidationSchema = Yup.object().shape({
    from_name: Yup.string()
      .min(2, "Too short")
      .max(50, "Too Long")
      .required("Name Required"),
    mail: Yup.string()
      .matches(mailRegExp, "Invalid email")
      .required("Email required"),
    emailBody: Yup.string().required("Message required"),
    // tel: Yup.string().notRequired().phone("DN", false, "Enter Valid Phone"),
    /* phone: Yup.string().when("phone", (val, schema) => {
              if (val?.length > 0) {
                return Yup.string()
                  .transform((val) => {
                    val.padStart(val.length + 1, "+");
                  })
                  .phone("DN", false)
                  .required();
              } else return Yup.string().notRequired();
            }),*/
  });

  const handleSubmit = async (values) => {
    const recValue = captchaRef.current?.getValue();
    captchaRef.current?.reset();
    //console.log("values", values);
    const params = { ...values, "g-recaptcha-response": recValue };
    params.phone =
      params.phone.length > 0
        ? params.phone.padStart(params.phone.length + 1, "+")
        : "";
    params.emailFromName = "Zvw Home";
    params.emalFrom = "info@zvwhome.com";
    params.emailSubject = "Message from ZVWHOME Web Site";
    params.emailBody = params.emailBody
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    params.from_name = params.from_name
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    params.skype = params.skype
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    params.phone = params.phone
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    params.mail = params.mail
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    const skype = params.skype.length > 0 ? `Skype: ${params.skype}` : "";
    const phone =
      params.phone.length > 0 ? `Phone / WhatsApp: ${params.phone}` : "";
    const skypeShow = params.skype.length > 0 ? "table-cell" : "none";
    const phoneShow = params.phone.length > 0 ? "table-cell" : "none";
    let colSpan = 2;
    if (params.skype.length > 0 && params.phone.length > 0) {
      colSpan = 1;
    }

    if (params.skype.length === 0 && params.phone.length === 0) {
      colSpan = 0;
    }

    const body = `<table style="width: 100%";>
<tr style = "background-color: #9f9f9f;">
<td style="padding: 10px; border: #e9e9e9 1px solid;"><b>Sender Name: ${params.from_name}</b></td>
<td style="padding: 10px; border: #e9e9e9 1px solid;"><b>Sender Email: ${params.mail}</b></td>
</tr>
<tr style = "background-color: #cdcccc;">
 <td colspan = ${colSpan} style="padding: 10px; border: #e9e9e9 1px solid; display: ${skypeShow}"><b>${skype}</b></td>
 <td colspan = ${colSpan} style="padding: 10px; border: #e9e9e9 1px solid; display: ${phoneShow}"><b>${phone}</b></td>
</tr>

<tr  style = "background-color: #9f9f9f;">
<td colspan="2" style="padding: 10px; border: #e9e9e9 1px solid;"><b>Sender Message: </b> ${params.emailBody}</td>
</tr> 
</table>`;

    params.emailRecipient = "ol@zvw-services-asia.com";
    // console.log("PARAMS", params);
    const request = new FormData();
    request.append("emailFromName", params.emailFromName);
    request.append("emailRecipient", params.emailRecipient);
    request.append("emailFrom", params.emalFrom);
    request.append("emailSubject", params.emailSubject);
    request.append("emailBody", body);
    //request.append("g-recaptcha-response", params["g-recaptcha-response"]);

    const response = await fetch(`${process.env.REACT_APP_API_ROUTE}`, {
      method: "POST",
      body: request,
    });

    const data = await response.json();
    if (data) {
      data.STATUS === 1
        ? alert("Message has been sent")
        : alert(`Message send failure - ${data.message}`);
    }
  };

  function expired() {
    setDisabled(true);
  }

  useEffect(() => {
    captchaRef.current?.reset();
    //  emailjs.init(`${process.env.REACT_APP_MAIL_PUBLIC_KEY}`);
  }, []);

  return (
    <div className={styles.container}>
      <h2>{t("contact.contact_header")}</h2>
      <div className={styles.main}>
        <div className={styles.formContainer}>
          <Formik
            initialValues={{
              //to_name: "",
              skype: "",
              from_name: "",
              emailBody: "",
              mail: "",
              phone: "",
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              setDisabled(true);
              resetForm();
            }}
          >
            {({ errors, touched }) => (
              <Form className={styles.form}>
                <div className={nameField}>
                  <label className={styles.labelStyle} htmlFor="from_name">
                    {t("contact.name")}
                  </label>
                  <Field
                    className={styles.inputField}
                    id="from_name"
                    name="from_name"
                    placeholder="Name"
                  />
                  {errors.from_name && touched.from_name ? (
                    <div className={styles.errorMessage}>
                      {errors.from_name}
                    </div>
                  ) : null}
                </div>
                <div className={skypeField}>
                  <label className={styles.labelStyle} htmlFor="skype">
                    Skype (optional)
                  </label>
                  <Field
                    className={styles.inputField}
                    id="skype"
                    name="skype"
                    placeholder="Skype"
                  />
                </div>
                <div className={phoneField}>
                  <PhoneField
                    className={styles.inputField}
                    name="phone"
                    type="tel"
                    label={t("contact.phone")}
                    id="phone"
                  />
                </div>

                <div className={mailField}>
                  <label className={styles.labelStyle} htmlFor="email">
                    {t("contact.mail")}
                  </label>
                  <Field
                    className={styles.inputField}
                    id="mail"
                    name="mail"
                    placeholder="jane@acme.com"
                    type="email"
                  />
                  {errors.mail && touched.mail ? (
                    <div className={styles.errorMessage}>{errors.mail}</div>
                  ) : null}
                </div>

                <div className={messageField}>
                  <TextField
                    className={styles.inputField}
                    name="emailBody"
                    type="text"
                    label={t("contact.message")}
                    placeholder="200 max"
                  />
                </div>
                <input
                  className={styles.button}
                  type="submit"
                  value={t("contact.send")}
                  disabled={disabled}
                />

                <div className={styles.captcha}>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={gKey}
                    onChange={onChange}
                    onExpired={expired}
                    //size="compact"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className={styles.address}>
          <div className={styles.addressLinesContainer}>
            <div className={styles.header}>
              <img className={styles.logoImg} src={logo} alt={"company logo"} />

              <div className={styles.smallBlock}>
                <h3 className={styles.addressHeader}>ZvW Home Co. Ltd.</h3>
                <p className={styles.addressLine1}>106/51, 106/52 Moo1</p>
                <p className={styles.addressLine1}>Bophut Koh Samui</p>
                <p className={styles.addressLine1}>Surattani 84320</p>
                <p className={styles.addressLine1}>Thailand</p>
                <br />
              </div>
            </div>
            <p className={styles.addressLine}>Phone: +66 [0] 65 919 48 96</p>
            <p className={styles.addressLine}>
              E-Mail:&nbsp;&nbsp;
              <a className={styles.emailLink} href="mailto:info@zvwhome.com">
                info@zvwhome.com
              </a>
            </p>
          </div>
          <LoadScript googleMapsApiKey={`${process.env.REACT_APP_API_MAPS}`}>
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerClassName={styles.mapContainer}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
}
