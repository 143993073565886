import React, { useLayoutEffect, useRef } from "react";
import styles from "./portfolio.module.css";
import { useTranslation } from "react-i18next";
import icon1 from "../../assets/icons/software.webp";
import icon2 from "../../assets/icons/project.webp";
import icon3 from "../../assets/icons/design.webp";
import icon4 from "../../assets/icons/database.webp";
import icon5 from "../../assets/icons/webdev.webp";
import icon6 from "../../assets/icons/app.webp";
import icon7 from "../../assets/icons/development.webp";
import centerIcon from "../../assets/icons/center_icon.webp";
import crossPlatform from "../../assets/images/cross-platform.jpg";
import classNames from "classnames";
import { Separator } from "../Separator";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function Portfolio() {
  const icon7Class = classNames(styles.iconImg, styles.icon7);
  const wrap1 = classNames(styles.iconWrap, styles.icon1Wrap);
  const wrap3 = classNames(styles.iconWrap, styles.icon3Wrap);
  const wrap4 = classNames(styles.iconWrap, styles.icon4Wrap);
  const wrap6 = classNames(styles.iconWrap, styles.icon6Wrap);
  const wrap7 = classNames(styles.iconWrap, styles.icon7Wrap);
  const wrap9 = classNames(styles.iconWrap, styles.icon9Wrap);

  const leftDescription = classNames(
    styles.iconDescription,
    "description",
    styles.iconDescriptionLeft
  );

  const rightDescription = classNames(
    styles.iconDescription,
    "description",
    styles.iconDescriptionRight
  );

  const main = useRef();
  const { t } = useTranslation();
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const desc = self.selector(".description");

      let tl = gsap
        .timeline()
        .to(
          desc[1],
          {
            opacity: 1,
          },
          ">"
        )
        .to(desc[2], { opacity: 1 }, ">")

        .to(desc[4], { opacity: 1 }, ">")

        .to(desc[6], { opacity: 1 }, ">")
        .to(desc[5], { opacity: 1 }, ">")
        .to(desc[3], { opacity: 1 }, ">")
        .to(desc[0], { opacity: 1 })

        .reverse();

      ScrollTrigger.create({
        trigger: ".trigger",
        start: "bottom bottom",
        end: "bottom bottom",
        scrub: 1,
        animation: tl,
      });
    }, main);
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles.container}>
      <h2>{t("portfolio.portfolio_header")}</h2>
      <div className={styles.description}>
        <div className={styles.descriptionText}>
          <p>{t("portfolio.top_left")}</p>
        </div>
        <div className={styles.descriptionText}>
          <p>{t("portfolio.top_right")}</p>
        </div>
      </div>
      <div className={styles.circleContainer} ref={main}>
        <div className={styles.deg1}>
          <div className={wrap1}>
            <div>
              <p className={leftDescription}>{t("portfolio.backend")}</p>
            </div>
            <div className={styles.icon}>
              <img className={styles.iconImg} src={icon7} alt="backend icon" />
            </div>
          </div>
        </div>
        <div className={styles.deg2}>
          <div className={styles.iconWrap}>
            <p className={rightDescription}>{t("portfolio.software_icon")}</p>

            <div className={styles.icon}>
              <img className={styles.iconImg} src={icon1} alt="software icon" />
            </div>
          </div>
        </div>
        <div className={styles.deg3}>
          <div className={wrap3}>
            <div className={styles.icon}>
              <img className={styles.iconImg} src={icon2} alt="project icon" />
            </div>
            <div>
              <p className={rightDescription}>{t("portfolio.project")}</p>
            </div>
          </div>
        </div>
        <div className={styles.deg4}>
          <div className={wrap4}>
            <div>
              <p className={leftDescription}>{t("portfolio.app")}</p>
            </div>
            <div className={styles.icon}>
              <img className={styles.iconImg} src={icon6} alt="app icon" />
            </div>
          </div>
        </div>
        <div className={styles.deg5}>
          <div className={styles.iconWrap}>
            <div className={styles.centerIcon}>
              <img
                className={styles.centerIconImg}
                src={centerIcon}
                alt="center icon"
              />
            </div>
          </div>
        </div>

        <div className={styles.deg6}>
          <div className={wrap6}>
            <div className={styles.icon}>
              <img className={styles.iconImg} src={icon3} alt="design icon" />
            </div>
            <div>
              <p className={rightDescription}>{t("portfolio.design")}</p>
            </div>
          </div>
        </div>
        <div className={styles.deg7}>
          <div className={wrap7}>
            <div>
              <p className={leftDescription}>{t("portfolio.development")}</p>
            </div>
            <div className={styles.icon}>
              <img className={icon7Class} src={icon5} alt="development icon" />
            </div>
          </div>
        </div>
        <div className={styles.deg9}>
          <div className={"trigger"}>
            <div className={wrap9}>
              <div className={styles.icon}>
                <img className={icon7Class} src={icon4} alt="database icon" />
              </div>
              <div>
                <p id={"trigger"} className={rightDescription}>
                  {t("portfolio.database")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerCont}>
        <div className={styles.portfolioFooter}>
          <img
            className={styles.crossPlatformImg}
            src={crossPlatform}
            alt="cross-platform"
          />
        </div>
        <p className={styles.portfolioFooterText}>
          {t("portfolio.bottom1")}
          <br />
          <br />
          {t("portfolio.bottom2")}
        </p>
        <br />
        <br />
      </div>

      <Separator />
    </div>
  );
}
